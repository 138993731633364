import React, { Component } from 'react'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import CardMedia from '@material-ui/core/CardMedia';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { deepOrange } from '@material-ui/core/colors';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Region from "../Region/Region";
import Login from '../Login/Login';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { CircleToBlockLoading } from 'react-loadingg';
import ProjectsHome from '../Project/ProjectsHome';

const drawerWidth = 240;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
});

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#005D99',
        },
        secondary: {
            main: '#0091B5',
        },
    }
})

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDrawer: false,
            isLoading: true,
            viewClass: 'home',
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        const { classes } = this.props;
        const { history } = this.props;
        const handleDrawerOpen = () => { this.setState({ openDrawer: true }) };
        const handleDrawerClose = () => { this.setState({ openDrawer: false }) };
        const handleViewChange = (e, val) => {
            this.setState({ viewClass: val });
            if (val === "Logout") {
                localStorage.removeItem('accessToken');
                history.push('/login');
            }
        };
        return (
            <ThemeProvider theme={bbColorTheme}>
                {
                    (this.state.isLoading) ?
                        (<CircleToBlockLoading color='#005D99' />) :
                        (this.state.viewClass === 'Logout') ? (<Login />) :
                            (
                                <div className={classes.root}>
                                    <CssBaseline />
                                    <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: this.state.openDrawer })}>
                                        <Toolbar>
                                            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, { [classes.hide]: this.state.openDrawer, })}>
                                                <MenuIcon />
                                            </IconButton>
                                            <Typography variant="h6" noWrap className={classes.title}>
                                                Condition Assessment
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <Drawer variant="permanent" className={clsx(classes.drawer, { [classes.drawerOpen]: this.state.openDrawer, [classes.drawerClose]: !this.state.openDrawer, })} classes={{ paper: clsx({ [classes.drawerOpen]: this.state.openDrawer, [classes.drawerClose]: !this.state.openDrawer, }), }} >
                                        <div className={classes.toolbar}>
                                            <CardMedia component="img" alt="Balfour Beatty" image={process.env.PUBLIC_URL + '/bb.png'} title="Balfour Beatty" style={{ width: '170px' }} />
                                            <IconButton onClick={handleDrawerClose}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </div>
                                        <Divider />
                                        <List>
                                            {['Regions', 'ProjectsHome', 'Logout'].map((text, index) => (
                                                <ListItem button key={text} onClick={e => handleViewChange(e, text)}>
                                                    <ListItemIcon>
                                                        {
                                                            index === 0 ? <LocationOnIcon /> :
                                                                index === 1 ? <AccountTreeIcon /> :
                                                                    index === 2 ? <ExitToAppIcon /> :
                                                                        <InboxIcon />
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Divider />
                                    </Drawer>
                                    <main className={classes.content}>
                                        <div className={classes.toolbar} />
                                        {
                                            (!this.state.isLoading)
                                                ?
                                                ((this.state.viewClass === 'home') ? (<Region />) :
                                                    (this.state.viewClass === 'Regions') ? (<Region />) :
                                                        (this.state.viewClass === 'ProjectsHome') ? (<ProjectsHome />) :
                                                            (null)
                                                )
                                                : (null)
                                        }
                                    </main>

                                </div>
                            )
                }
            </ThemeProvider>
        )
    }
}
export default withStyles(useStyles)(withSnackbar(Home));