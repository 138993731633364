
import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Grid, Dialog, DialogContent, DialogContentText, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import axios from 'axios';
import { withSnackbar } from 'notistack';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
const mainProjectIdentifier = '#main.cautionDoNotRemove.'
const subProjectIdentifier = (projectID) => {
    return `#${projectID}.cautionDoNotRemove.`
}

class NotGroupedProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            regionLookup: {},
            deleting: false,
            userAddModal: false,
            hierarchy: {},
            accessToken: localStorage.getItem('accessToken')
        }
    }

    componentDidMount() {
        this.getProjects();
    }

    getIdNameFomattedObj = (arr) => {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: ele.name.slice(ele.name.indexOf('.cautionDoNotRemove.') + 21) } }
        })
        return obj
    }
    updateHierarchy = (fieldKey, subProject) => {
        let dupHierarchy = { ...this.state.hierarchy }
        dupHierarchy[fieldKey].push(subProject)

        this.setState({ hierarchy: dupHierarchy })
    }
    clearName(subProjects) {
        subProjects.forEach(proj => proj.name = proj.name.includes('.cautionDoNotRemove.') ? proj.name.slice(proj.name.indexOf('.cautionDoNotRemove.') + 21) : proj.name)
        return subProjects
    }

    makeHierarchy(allProjects) {
        let hierarchy = {};
        let mainProjects = [];
        let notMainProjects = [];

        for (let projectIndex = 0; projectIndex < allProjects.length; projectIndex++) {
            const project = allProjects[projectIndex];
            if (project.hasOwnProperty('name')) {
                let projectTypee = project.name.slice(0, project.name.indexOf('.cautionDoNotRemove.'))
                if (projectTypee === '#main') {
                    mainProjects.push(project)
                    hierarchy = { ...hierarchy, [project.id]: [] }
                } else {
                    notMainProjects.push(project)
                }
            } else {
                notMainProjects.push(project)
            }
        }


        let groupedItems = [];
        Object.keys(hierarchy).forEach(mainproj => {
            for (let proIndex = 0; proIndex < notMainProjects.length; proIndex++) {
                const projt = notMainProjects[proIndex];
                if (projt.hasOwnProperty('name')) {
                    let projMainID = projt.name.slice(1, projt.name.indexOf('.cautionDoNotRemove.'))
                    if (mainproj === projMainID) {
                        hierarchy[projMainID].push(projt)
                        groupedItems.push(projt)

                    }
                }
            }


        })

        let notGroupedArray = notMainProjects.filter(prjt => {
            let grouped = false

            for (let itemIndex = 0; itemIndex < groupedItems.length; itemIndex++) {
                const grpdprojt = groupedItems[itemIndex];
                if (grpdprojt.id === prjt.id) {
                    grouped = true
                    break
                }
            }
            return !grouped

        })

        notGroupedArray.forEach((prjt, prjtIndex) => notGroupedArray[prjtIndex] = { ...prjt, 'group': 'Nil' })
        notGroupedArray = this.clearName(notGroupedArray)
        hierarchy = { ...hierarchy, notGrouped: notGroupedArray }
        let returnValues = { hierarchy, mainProjects }

        return returnValues
    }

    getRegions() {
        let _regionLookup = {};
        axios.get(`https://app.connect.trimble.com/tc/api/2.0/regions`, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                _regionLookup = {};
                res.data.forEach((data) => {
                    _regionLookup[data.location] = data.location;
                });
            }).catch(ex => {
                _regionLookup = {};
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                this.setState({ regionLookup: _regionLookup });
            });
    }

    getProjects() {

        this.getRegions();

        axios.get(`https://app.connect.trimble.com/tc/api/2.0/projects?fullyLoaded=true&minimal=true&sort=-name`, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                let { hierarchy, mainProjects } = this.makeHierarchy(res.data)
                let mainprojectsLookup = this.getIdNameFomattedObj(mainProjects)
                this.setState({ hierarchy, mainProjects, mainprojectsLookup })
            }).catch(ex => {
                console.log(ex)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })

    }


    addProject(data, resolve, reject) {

        let _project = {
            location: data.location,
            name: `${mainProjectIdentifier} ${data.name}`
        }
        axios.post(`https://app.connect.trimble.com/tc/api/2.0/projects`, _project, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.setState({ mainProjects: [...this.state.mainProjects, res.data] })
                this.props.enqueueSnackbar('Project Added!', { variant: 'success' });
                resolve();
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            })


    }

    updateProject(data, resolve, reject) {
        if (data.group === 'main') {
            data.name = `${mainProjectIdentifier} ${data.name}`
        } else {
            data.name = `${subProjectIdentifier(data.group)} ${data.name}`
        }
        axios.patch(`https://app.connect.trimble.com/tc/api/2.0/projects/` + data.id, data, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.props.enqueueSnackbar('Project Updated!', { variant: 'success' });
                resolve();
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            }).finally(() => {
                this.getProjects();
            });


    }

    deleteProject(data, resolve, reject) {
        this.setState({ deleting: true })
        data.name = data.name + "test";
        axios.delete(`https://app.connect.trimble.com/tc/api/2.0/projects/` + data.id, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.setState({ deleting: false })
                this.props.enqueueSnackbar('Project Deleted!', { variant: 'success' });

                resolve();
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            }).finally(() => {
                this.getProjects();
            });
    }

    render() {
        return (
            <div>
                <Grid >

                    <MaterialTable
                        title="List of Projects"
                        columns={[
                            {
                                title: 'Project Name', field: 'name', render: rowData => {
                                    let projectViewUrl = 'https://web.connect.trimble.com/projects/' + rowData.id.trim() + '/data/folder/';
                                    return <a href={projectViewUrl} rel="noopener noreferrer" target="_blank" >{rowData.name} </a>;
                                }
                            },
                            { title: 'Location', field: 'location', lookup: this.state.regionLookup },
                            { title: 'Group', field: 'group', lookup: { ...this.state.mainprojectsLookup, 'main': '#main' } },
                        ]}
                        data={this.state.hierarchy.notGrouped}
                        options={{
                            paging: false,
                            pageSize: 20,
                            pageSizeOptions: [10, 20, 50, 100],
                            maxBodyHeight: window.screen.height - 250,
                            headerStyle: {
                                fontWeight: 'bold',
                                // color: '#005D99',
                            },
                            actionsColumnIndex: -1,
                            filtering: true
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    this.addProject(newData, resolve, reject);
                                }),
                            onRowUpdate: (newData, oldData) =>

                                new Promise((resolve, reject) => {
                                    this.updateProject(newData, resolve, reject);
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    this.deleteProject(oldData, resolve, reject);
                                }),
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}

                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                onClick: (event) => {
                                    this.setState({ userAddModal: true })
                                }
                            },

                        ]}
                    />
                </Grid>

                {this.state.deleting ? <Dialog
                    open={this.state.deleting}
                    onClose={() => this.setState({ deleting: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'This operation takes 5 more minutes to complete.'}
                        </DialogContentText>
                    </DialogContent>
                    <Button onClick={() => this.setState({ deleting: false })} color="primary" autoFocus>
                        Close
                    </Button>
                </Dialog> : null}
            </div >
        )
    } npm
}

export default withStyles(useStyles)(withSnackbar(NotGroupedProjects));