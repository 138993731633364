import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import axios from 'axios';
import { withSnackbar } from 'notistack';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

class Region extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            regions: [],
            isRegionsLoaded: true,
            accessToken: localStorage.getItem('accessToken')
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getRegions();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getRegions() {
        if (this._isMounted === true) {
            let _regions = [];
            axios.get(`https://app.connect.trimble.com/tc/api/2.0/regions`, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
                .then(res => {
                    _regions = res.data;
                }).catch(ex => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                }).finally(() => {
                    this.setState({ isRegionsLoaded: false, regions: _regions });
                });
        }
    }

    render() {
        return (
            <div>
                <Grid >
                    <MaterialTable
                        title="List of Regions"
                        columns={[
                            { title: 'Location', field: 'location' },
                            { title: 'AWS Region', field: 'awsRegion' },
                            { title: 'Is Master', field: 'isMaster' },
                            { title: 'Service Region', field: 'serviceRegion' },
                        ]}
                        data={this.state.regions}
                        isLoading={this.state.isRegionsLoaded}
                    />
                </Grid>
            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(Region));