import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Dialog, DialogContent, DialogContentText, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import axios from 'axios';
import { withSnackbar } from 'notistack';


const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const subProjectIdentifier = (projectID) => {
    return `#${projectID}.cautionDoNotRemove.`
}

class SubProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            deleting: false,
            userAddModal: false,
            accessToken: localStorage.getItem('accessToken')
        }
    }

    componentDidMount() {
        this.setState({ ...this.props })
    }

    addSubProject(data, resolve, reject) {

        let _project = {
            name: `${subProjectIdentifier(this.props.parentProj.id)} ${data.name}`,
            location: this.props.parentProj.location,
        }
        axios.post(`https://app.connect.trimble.com/tc/api/2.0/projects`, _project, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                res.data.name = res.data.name.slice(res.data.name.indexOf('.cautionDoNotRemove.') + 21)
                this.props.updateHierarchy(this.props.parentProj.id, res.data)
                this.props.enqueueSnackbar('Project Added!', { variant: 'success' });
                resolve();
            }).catch(ex => {
                console.log(_project, ex)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            })
    }
    updateProject(data, resolve, reject) {
        data.name = `${subProjectIdentifier(this.props.parentProj.id)} ${data.name}`

        axios.patch(`https://app.connect.trimble.com/tc/api/2.0/projects/` + data.id, data, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.props.modifyHierarchy(this.props.parentProj.id, res.data)
                this.props.enqueueSnackbar('Project Updated!', { variant: 'success' });
                resolve();
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            })


    }

    deleteProject(data, resolve, reject) {
        this.setState({ deleting: true })
        axios.delete(`https://app.connect.trimble.com/tc/api/2.0/projects/` + data.id, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.setState({ deleting: false })
                this.props.enqueueSnackbar('Project Deleted!', { variant: 'success' });
                resolve();
            }).catch(ex => {
                // console.log(ex);
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                reject();
            })
    }

    render() {
        return (
            <div style={{ width: '98%' }}>
                <MaterialTable
                    title="List of Sub Projects"
                    columns={[
                        {
                            title: 'Project Names', field: 'name', render: rowData => {
                                let projectViewUrl = 'https://web.connect.trimble.com/projects/' + rowData.id.trim() + '/data/folder/';
                                return <a href={projectViewUrl} rel="noopener noreferrer" target="_blank" >{rowData.name.includes('.cautionDoNotRemove.') ? rowData.name.slice(rowData.name.indexOf('.cautionDoNotRemove.') + 21) : rowData.name} </a>;
                            }
                        },
                        // { title: 'Location', field: 'location', lookup: this.state.regionLookup, editable: 'onAdd' },
                    ]}
                    data={this.state.projects}
                    options={{
                        paging: false,
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50, 100],
                        maxBodyHeight: window.screen.height - 250,
                        headerStyle: {
                            fontWeight: 'bold',
                            // color: '#005D99',
                        },
                        actionsColumnIndex: -1,
                        filtering: true
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                this.addSubProject(newData, resolve, reject);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                this.updateProject(newData, resolve, reject);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                this.deleteProject(oldData, resolve, reject);
                            }),
                    }}
                    isLoading={this.state.isProjectsLoaded}
                />

                {this.state.deleting ? <Dialog
                    open={this.state.deleting}
                    onClose={() => this.setState({ deleting: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'This operation takes 5 more minutes to complete.'}
                        </DialogContentText>
                    </DialogContent>
                    <Button onClick={() => this.setState({ deleting: false })} color="primary" autoFocus>
                        Close
                    </Button>
                </Dialog> : null}
            </div >
        )
    }
}

export default withStyles(useStyles)(withSnackbar(SubProjects));