import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
import InputIcon from '@material-ui/icons/Input';
import OAuth2Login from 'react-simple-oauth2-login';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
// import { useHistory } from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Powered by Engineering Design Center, India'}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/trimble_connect.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: '800px 100px',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { access_token: localStorage.getItem('accessToken') };
    }


    render() {
        const { classes } = this.props;
        const { history } = this.props;

        const onSuccess = ({ access_token: token }) => {
            localStorage.setItem('accessToken', token);
            this.setState({ access_token: token });
            history.push('/Home');
        };

        const onFailure = (errors) => {
            this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
        };
        return (
            <Grid container component="main" className={classes.root} >
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <CardMedia component="img" alt="Balfour Beatty" image={process.env.PUBLIC_URL + '/bb.png'} title="Balfour Beatty" style={{ width: '300px', height: '125px' }} />
                        <Typography align="center" component="h2" variant="h4">
                            Condition Assessment
                        </Typography>
                        <br />
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h2" variant="h5">
                            Sign in
                        </Typography>
                        <br />
                        <CardMedia component="img" alt="Balfour Beatty" image={process.env.PUBLIC_URL + '/trimble_connect.svg'} title="Balfour Beatty" style={{ width: '300px' }} />
                        <br />
                        <form className={classes.form} noValidate>
                            < OAuth2Login
                                authorizationUrl="https://id.trimble.com/oauth/authorize"
                                responseType="token"
                                clientId="8cb60441-bd37-4dd2-a26b-7b39eeb6a193"
                                clientSecret="2287542731a1471db1ab5d0b70b50fb1"
                                redirectUri={window.location.origin}
                                scope="openid BB-Portal"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                render={renderProps => (
                                    <Button fullWidth variant="contained" endIcon={<InputIcon />} color="primary" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                        Sign in
                                    </Button>
                                )}
                            />
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(useStyles)(withSnackbar(Login));
