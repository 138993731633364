import React, { Component } from 'react'
import { withStyles, Grid, Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, IconButton, TextField } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import SubProjects from './SubProjects';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ChevronRightOutlined } from '@material-ui/icons';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
const mainProjectIdentifier = '#main.cautionDoNotRemove.'


class Project extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            regionLookup: {},
            deleting: false,
            deletingDetails: {},
            userAddModal: false,
            hierarchy: {},
            accessToken: localStorage.getItem('accessToken'),
            mainProjects: [],
            addingProject: false,
            updatingProject: false,
            updatingProjectDetails: {},
            projectName: '',
            selectedLocation: null,
            showSubProjects: false,
            mainProjectIndex: null,
        }
    }

    componentDidMount() {
        this.getRegions();
        this.getProjects();
    }

    updateHierarchy = (fieldKey, subProject) => {
        let dupHierarchy = { ...this.state.hierarchy }
        dupHierarchy[fieldKey].push(subProject)

        this.setState({ hierarchy: dupHierarchy })
    }
    modifyHierarchy = (fieldKey, subProject) => {
        let dupHierarchy = { ...this.state.hierarchy }
        for (let index = 0; index < dupHierarchy[fieldKey].length; index++) {
            const proj = dupHierarchy[fieldKey][index];
            if (proj.id === subProject.id) {
                proj.location = subProject.location
                proj.name = subProject.name.slice(subProject.name.indexOf('.cautionDoNotRemove.') + 21)
            }
        }
        this.setState({ hierarchy: dupHierarchy })
    }
    searchHandle(e) {
        let value = e.target.value
        if (value === "") {
            this.setState({ hierarchy: this.state.hierarchyCopy }, () => { return })
        }
        this.setState({ roleSearch: value }, this.searchHierarchy(this.state.hierarchyCopy, value))
    }
    searchHierarchy(hierarchyObj, searchString) {
        let filteredHierarchy = {}
        Object.keys(hierarchyObj).forEach((key => {
            if (key !== 'notGrouped') {
                for (let proIndex = 0; proIndex < hierarchyObj[key].length; proIndex++) {
                    const project = hierarchyObj[key][proIndex];
                    let projectName = project.name.includes('.cautionDoNotRemove.') ? project.name.slice(project.name.indexOf('.cautionDoNotRemove.') + 21) : project.name
                    if (projectName.includes(searchString)) {
                        if (filteredHierarchy.hasOwnProperty(key)) {
                            filteredHierarchy[key].push(project)

                        } else {
                            filteredHierarchy = { ...filteredHierarchy, [key]: [project] }

                        }
                    }
                }

            }
        }))

        this.setState({ hierarchy: filteredHierarchy })
    }
    makeHierarchy(allProjects) {
        let hierarchy = {};
        let mainProjects = [];
        let notMainProjects = [];

        for (let projectIndex = 0; projectIndex < allProjects.length; projectIndex++) {
            const project = allProjects[projectIndex];
            if (project.hasOwnProperty('name')) {
                let projectTypee = project.name.slice(0, project.name.indexOf('.cautionDoNotRemove.'))
                if (projectTypee === '#main') {
                    mainProjects.push(project)
                    hierarchy = { ...hierarchy, [project.id]: [] }
                } else {
                    notMainProjects.push(project)
                }
            } else {
                notMainProjects.push(project)
            }
        }


        let groupedItems = [];
        Object.keys(hierarchy).forEach(mainproj => {
            for (let proIndex = 0; proIndex < notMainProjects.length; proIndex++) {
                const projt = notMainProjects[proIndex];
                if (projt.hasOwnProperty('name')) {
                    let projMainID = projt.name.slice(1, projt.name.indexOf('.cautionDoNotRemove.'))
                    if (mainproj === projMainID) {
                        hierarchy[projMainID].push(projt)
                        groupedItems.push(projt)

                    }
                }
            }


        })

        let notGroupedArray = notMainProjects.filter(prjt => {
            let grouped = false

            for (let itemIndex = 0; itemIndex < groupedItems.length; itemIndex++) {
                const grpdprojt = groupedItems[itemIndex];
                if (grpdprojt.id === prjt.id) {
                    grouped = true
                    break
                }
            }
            return !grouped

        })

        hierarchy = { ...hierarchy, notGrouped: notGroupedArray }
        let returnValues = { hierarchy, mainProjects }

        return returnValues
    }

    getRegions() {
        let _regionLookup = {};
        axios.get(`https://app.connect.trimble.com/tc/api/2.0/regions`, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                _regionLookup = {};
                res.data.forEach((data) => {
                    _regionLookup[data.location] = data.location;
                });
                this.setState({ regionLookup: _regionLookup, locations: res.data });
            }).catch(ex => {
                _regionLookup = {};
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
            });
    }
    getAllProjectDetails(projects) {

        Promise.all(projects.map((proj) => axios.get(`https://app.connect.trimble.com/tc/api/2.0/projects/${proj.id}`))).then(res => console.log(res)).catch(err => console.log(err))
    }

    getProjects() {

        axios.get(`https://app.connect.trimble.com/tc/api/2.0/projects?fullyLoaded=true&minimal=true&sort=-name`, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                // this.getAllProjectDetails(res.data)
                let { hierarchy, mainProjects } = this.makeHierarchy(res.data)
                this.setState({ hierarchy, hierarchyCopy: hierarchy, mainProjects })
            }).catch(ex => {
                console.log(ex)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })

    }


    addProject(e) {
        let { selectedLocation, projectName } = this.state;
        if (selectedLocation && projectName) {
            let _project = {
                location: selectedLocation.location,
                name: `${mainProjectIdentifier} ${projectName}`
            }
            axios.post(`https://app.connect.trimble.com/tc/api/2.0/projects`, _project, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
                .then(res => {
                    this.setState({ mainProjects: [...this.state.mainProjects, res.data], addingProject: false, projectName: '', selectedLocation: null })
                    this.props.enqueueSnackbar('Project Added!', { variant: 'success' });
                }).catch(ex => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                })

        } else {

            this.props.enqueueSnackbar('Please give project name and location!', { variant: 'error' });
        }
    }

    updateProject(e) {
        let data = { ...this.state.updatingProjectDetails }
        data.name = `${mainProjectIdentifier} ${this.state.projectName}`

        axios.patch(`https://app.connect.trimble.com/tc/api/2.0/projects/` + this.state.updatingProjectDetails.id, data, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.setState({ updatingProjectDetails: data, updatingProject: false, projectName: '' })
                this.props.enqueueSnackbar('Project Updated!', { variant: 'success' });
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                this.getProjects();
            });


    }
    clearName(subProjects) {
        subProjects.forEach(proj => proj.name = proj.name.includes('.cautionDoNotRemove.') ? proj.name.slice(proj.name.indexOf('.cautionDoNotRemove.') + 21) : proj.name)
        return subProjects
    }
    deleteProject() {

        axios.delete(`https://app.connect.trimble.com/tc/api/2.0/projects/` + this.state.deletingDetails.id, { headers: { "Authorization": `Bearer ${this.state.accessToken}` } })
            .then(res => {
                this.setState({ deleting: false, deletingDetails: null })
                this.props.enqueueSnackbar('Project Deleted!', { variant: 'success' });
            }).catch(ex => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                this.getProjects();
            });
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}
                    alignItems="center">
                    <Grid item>
                        <div style={{
                            paddingLeft: '1rem',
                            width: '100%',
                        }}>
                            <TextField
                                onChange={(e) => this.searchHandle(e)}
                                id="standard-basic"
                                label="Search Projects"
                                type="text"
                                variant="standard"
                                value={this.state.roleSearch}

                            />
                            {this.state.roleSearch ? (
                                <IconButton
                                    aria-label="close"
                                    onClick={() => { this.setState({ roleSearch: "", hierarchy: this.state.hierarchyCopy }) }}
                                    style={{
                                        position: "relative",
                                        left: "1%",
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}
                    alignItems="center">
                    <Grid item>
                        <Typography variant='h5'>{"List of Projects"}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" onClick={(event) => this.setState({ addingProject: true })} aria-label="close">
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container
                    justifyContent="space-evenly"
                    alignItems="center" wrap={'nowrap'}>
                    <Grid
                        item container
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={2}
                    >
                        <Grid item><Typography variant='h6'>{'Actions'}</Typography></Grid>
                        <Grid item><IconButton></IconButton></Grid>
                        <Grid item><IconButton></IconButton></Grid>

                    </Grid>
                    <Grid xs={10} item container justifyContent="space-around" alignItems="center">
                        <Grid item><Typography variant='h6'>{'Project Name'}</Typography></Grid>
                        <Grid item><Typography variant='h6'>{'Location'}</Typography></Grid>
                    </Grid>
                </Grid>

                {
                    this.state.mainProjects.map((mainProject, mainProjectIndex) => this.state.roleSearch ? this.state.hierarchy.hasOwnProperty(mainProject.id) ? <Grid container key={mainProjectIndex} justifyContent="space-evenly"
                        alignItems="center">
                        <Grid item container
                            justifyContent="flex-start"
                            alignItems="center" wrap={'nowrap'} xs={2}>
                            <Grid item><IconButton onClick={() => { this.setState({ showSubProjects: !this.state.showSubProjects, mainProjectIndex }) }}>{this.state.showSubProjects && this.state.mainProjectIndex === mainProjectIndex ? <ChevronRightOutlined style={{ transform: 'rotate(90deg)' }} /> : <ChevronRightOutlined />}</IconButton></Grid>
                            <Grid item> <IconButton edge="start" color="inherit" onClick={(e) => { e.preventDefault(); this.setState({ deleting: true, deletingDetails: mainProject }) }} aria-label="close">
                                <CloseIcon />
                            </IconButton></Grid>
                            <Grid item> <IconButton edge="start" color="inherit" onClick={(e) => { e.preventDefault(); this.setState({ updatingProject: true, updatingProjectDetails: mainProject, projectName: mainProject.name.slice(mainProject.name.indexOf('.cautionDoNotRemove.') + 21) }) }
                            } aria-label="close">
                                <EditIcon />
                            </IconButton></Grid></Grid>
                        <Grid xs={10} item container direction='row' justifyContent="space-around" alignItems="center">
                            <Typography>{mainProject.name.slice(mainProject.name.indexOf('.cautionDoNotRemove.') + 21)}</Typography>
                            <Typography>{mainProject.location}</Typography>
                        </Grid>
                        {
                            this.state.showSubProjects && this.state.mainProjectIndex === mainProjectIndex ?
                                <SubProjects getProjects={this.getProjects} updateHierarchy={this.updateHierarchy} modifyHierarchy={this.modifyHierarchy} projects={this.clearName(this.state.hierarchy[mainProject.id])} regionLookup={this.state.regionLookup} parentProj={mainProject} /> : null
                        }
                    </Grid> : null : <Grid container key={mainProjectIndex} justifyContent="space-evenly"
                        alignItems="center">
                        <Grid item container
                            justifyContent="flex-start"
                            alignItems="center" wrap={'nowrap'} xs={2}>
                            <Grid item><IconButton onClick={() => { this.setState({ showSubProjects: !this.state.showSubProjects, mainProjectIndex }) }}>{this.state.showSubProjects && this.state.mainProjectIndex === mainProjectIndex ? <ChevronRightOutlined style={{ transform: 'rotate(90deg)' }} /> : <ChevronRightOutlined />}</IconButton></Grid>
                            <Grid item> <IconButton edge="start" color="inherit" onClick={(e) => { e.preventDefault(); this.setState({ deleting: true, deletingDetails: mainProject }) }} aria-label="close">
                                <CloseIcon />
                            </IconButton></Grid>
                            <Grid item> <IconButton edge="start" color="inherit" onClick={(e) => { e.preventDefault(); this.setState({ updatingProject: true, updatingProjectDetails: mainProject, projectName: mainProject.name.slice(mainProject.name.indexOf('.cautionDoNotRemove.') + 21) }) }
                            } aria-label="close">
                                <EditIcon />
                            </IconButton></Grid></Grid>
                        <Grid xs={10} item container direction='row' justifyContent="space-around" alignItems="center">
                            <Typography>{mainProject.name.slice(mainProject.name.indexOf('.cautionDoNotRemove.') + 21)}</Typography>
                            <Typography>{mainProject.location}</Typography>
                        </Grid>
                        {
                            this.state.showSubProjects && this.state.mainProjectIndex === mainProjectIndex ?
                                <SubProjects getProjects={this.getProjects} updateHierarchy={this.updateHierarchy} modifyHierarchy={this.modifyHierarchy} projects={this.clearName(this.state.hierarchy[mainProject.id])} regionLookup={this.state.regionLookup} parentProj={mainProject} /> : null
                        }
                    </Grid>)
                }


                {
                    this.state.deleting ? <Dialog
                        open={this.state.deleting}
                        onClose={() => this.setState({ deleting: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {'This operation takes 5 more minutes to complete. To continue Press delete.'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => { e.preventDefault(); this.deleteProject() }} color="primary" autoFocus>
                                Delete
                            </Button>
                            <Button onClick={(e) => { e.preventDefault(); this.setState({ deleting: false }) }} color="primary" autoFocus>
                                Close
                            </Button>
                        </DialogActions>

                    </Dialog> : null
                }
                {
                    this.state.addingProject ? <Dialog
                        maxWidth={"sm"}
                        open={this.state.addingProject}
                        onClose={() => this.setState({ addingProject: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Grid container spacing={2} justifyContent="center" alignContent="center">
                                <Grid item sm={10}>
                                    <TextField
                                        id={'name'}
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        value={this.state.projectName}
                                        onChange={(e) => this.setState({ projectName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item sm={10}>
                                    <Autocomplete
                                        id="location"
                                        options={this.state.locations}
                                        getOptionLabel={(option) => option.location}
                                        onChange={(e, value) => this.setState({ selectedLocation: value })}
                                        value={this.state.selectedLocation}
                                        size="medium"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.location}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Location" placeholder="Location" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => this.addProject(e)} color="primary" autoFocus>
                                Add
                            </Button>
                            <Button onClick={() => this.setState({ addingProject: false })} color="primary" autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog> : null
                }
                {
                    this.state.updatingProject ? <Dialog
                        open={this.state.updatingProject}
                        onClose={() => this.setState({ updatingProject: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Grid container spacing={2} justifyContent="center" alignContent="center">
                                <Grid item sm={11}>
                                    <TextField
                                        id={'name'}
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        value={this.state.projectName}
                                        onChange={(e) => this.setState({ projectName: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => this.updateProject(e)} color="primary" autoFocus>
                                Update
                            </Button>
                            <Button onClick={() => this.setState({ updatingProject: false })} color="primary" autoFocus>
                                Close
                            </Button>

                        </DialogActions>
                    </Dialog> : null
                }
            </div >
        )
    }
}

export default withStyles(useStyles)(withSnackbar(Project));