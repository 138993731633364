import './App.css';
import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home/Home';
import Login from './Login/Login';
import './App.css'


class App extends Component {

  componentDidMount() {

  }

  render() {
    return (
      <div>
        <AppRoute />
      </div>
    )
  }
}

function AppRoute() {
  return (
    <BrowserRouter >
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/Home" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default (App);